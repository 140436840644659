.construction_wrapper > div {
  position: relative;
}

.construction_grey {
  position: absolute;
  left: -100%;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background: #ffffff;
}

.construction_content {
  padding: 7rem 0 7.5rem 0;
}

.construction_content > h3 {
  margin-bottom: 4rem;
}

.construction_content_scheme {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 6.25rem;
}

.construction_content_scheme > img {
  width: 50%;
}

.construction_content_scheme > div {
  width: 100%;
  max-width: 41.25rem;
}

.construction_content_scheme > div > img {
  width: 100%;
}

.construction_content_scheme > div > img:not(:last-child) {
  margin-bottom: 1.25rem;
}

.construction_content_benefit {
  margin-bottom: 10rem;
}

.construction_content_benefit_cardList > div {
  display: flex;
}

.construction_content_benefit_cardList > div:first-child {
  margin-bottom: 0.75rem;
}

.construction_content_benefit_cardList > div > div {
  max-width: 50%;
}

.construction_content_benefit_cardList > div > div:not(:last-child) {
  margin-right: 0.75rem;
}

.construction_content_benefit > p {
  margin: 2.75rem 0 2rem 0;
  max-width: 43.5rem;
}

.construction_content_benefit > a {
  text-decoration: none;
}

.construction_content_quality > h3 {
  margin-bottom: 4.5rem;
}

.construction_content_quality_cardList {
  display: flex;
}

.construction_content_quality_cardList
  > .construction_content_quality_card:not(:last-child) {
  padding-right: 2rem;
}

.construction_content_quality_card {
  width: 100%;
  max-width: 21rem;
}

.construction_content_quality_card > img {
  margin-bottom: 3rem;
}

.construction_content_quality_card > h4 {
  margin-bottom: 1.5rem;
  color: #f1b52f;
}

.construction_content_quality_card > p {
  font-size: 16px;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .construction_content {
    padding: 5rem 0;
  }

  .construction_content > h3 {
    margin-bottom: 2.5rem;
  }

  .construction_content_scheme {
    margin-bottom: 4rem;
  }

  .construction_content_scheme > div > img:not(:last-child) {
    margin-bottom: 1rem;
  }

  .construction_content_benefit {
    margin-bottom: 6rem;
  }

  .construction_content_benefit > p {
    margin: 0.75rem 0 1.5rem 0;
    max-width: 43.5rem;
  }

  .construction_content_quality > h3 {
    margin-bottom: 3.5rem;
  }

  .construction_content_quality_cardList
    > .construction_content_quality_card:not(:last-child) {
    padding-right: 1.25rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .construction_grey {
    display: none;
  }

  .construction_content {
    padding: 5rem 0 2.5rem 0;
  }

  .construction_content > h3 {
    margin-bottom: 2.5rem;
  }

  .construction_content_scheme {
    flex-direction: column;
    align-items: center;
    margin-bottom: 3.25rem;
  }

  .construction_content_scheme > img {
    width: 100%;
  }

  .construction_content_scheme > div > img:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  .construction_content_benefit {
    margin-bottom: 5rem;
  }

  .construction_content_benefit_cardList {
    margin-bottom: 0.75rem;
  }

  .construction_content_benefit_cardList > div {
    flex-direction: column;
  }

  .construction_content_benefit_cardList > div > div {
    max-width: 100%;
  }

  .construction_content_benefit_cardList > div > div:not(:last-child) {
    margin-right: 0;
    margin-bottom: 0.75rem;
  }

  .construction_content_benefit > p {
    margin: 0 0 2rem 0;
  }

  .construction_content_quality > h3 {
    margin-bottom: 2.5rem;
  }

  .construction_content_quality_cardList {
    flex-wrap: wrap;
  }

  .construction_content_quality_cardList
    > .construction_content_quality_card:not(:last-child) {
    margin-bottom: 2rem;
    padding-right: 1rem;
  }

  .construction_content_quality_card > img {
    margin-bottom: 1.5rem;
  }

  .construction_content_quality_card > h4 {
    margin-bottom: 0.75rem;
  }
}
