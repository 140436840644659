.emptyBlock {
  overflow: hidden;
}

.emptyBlock_grey {
  position: absolute;
  right: -100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background: #ffffff;
}

.emptyBlock_content {
  height: 8rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .emptyBlock_content {
    height: 6.25rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .emptyBlock_content {
    height: 4.25rem;
  }
}
