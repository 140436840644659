.hero {
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.05);
}

.hero_content {
  padding: 12rem 0 4.25rem 0;
}

.hero_content_h2 {
  margin-bottom: 1.5rem;
}

.hero_content_h2_active {
  padding-bottom: 4.5rem;
}

.hero_content > p {
  width: 100%;
  max-width: 31.75rem;
}

.hero_img {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 57.5rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .hero_content {
    padding: 9rem 0 2rem 0;
  }

  .hero_content_h2_active {
    padding-bottom: 7rem;
  }

  .hero_content > p {
    max-width: 23.75rem;
  }

  .hero_img {
    max-width: 46.5rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .hero_content {
    padding: 7.5rem 0 3rem 0;
  }

  .hero_content_h2 {
    margin-bottom: 1rem;
  }

  .hero_content_h2_active {
    padding-bottom: 7.75rem;
  }

  .hero_img {
    content: url('/public/assets/images/hero_product_mobile.png');
    width: 22.5rem;
    top: 2.5rem;
  }
}
