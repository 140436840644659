.yourProductSave {
  position: absolute;
  z-index: 1;
  opacity: 0.92;
  width: 100%;
  height: 100%;
  background-color: #070e10;
}

.yourProductSave > div,
.yourProductSave > div > div {
  height: 100%;
}

.yourProductSave_cross {
  width: 2.5rem;
  margin: 0 0 0 auto;
  padding-top: 1.5rem;
  transform: translateX(10rem);
  cursor: pointer;
}

.yourProductSave_content {
  display: flex;
  justify-content: space-between;
  padding-top: 7rem;
}

.yourProductSave_title {
  width: 100%;
  max-width: 33rem;
}

.yourProductSave_content > div:last-child {
  max-width: 43.5rem;
}

.yourProductSave_title > h2 {
  margin-bottom: 1.5rem;
}

.yourProductSave_title > p {
  max-width: 30rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .yourProductSave_cross {
    transform: translateX(0);
  }

  .yourProductSave_content {
    padding-top: 4.5rem;
  }

  .yourProductSave_content > div {
    max-width: 32.5rem;
  }

  .yourProductSave_title > p {
    max-width: 27rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .yourProductSave_cross {
    transform: translateX(0);
  }

  .yourProductSave_content {
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
  }

  .yourProductSave_content > div > button {
    margin-top: 2rem;
  }

  .yourProductSave_title {
    margin-bottom: 1.5rem;
  }

  .yourProductSave_title > h2 {
    margin-bottom: 1rem;
  }
}
