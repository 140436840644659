.installationRules {
  position: relative;
}

.installationRules_grey {
  position: absolute;
  left: -100%;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background: #ffffff;
}

.installationRules_content {
  position: relative;
  padding: 7.5rem 12.5rem 10rem 12.5rem;
}

.installationRules_content
  > .installationRules_content_foundation:not(:last-child) {
  margin-bottom: 12.5rem;
}

.installationRules_content_foundation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.installationRules_content_foundation > h3 {
  margin-bottom: 5rem;
}

.installationRules_content_foundation > img {
  width: 100%;
  max-width: 62.5rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .installationRules_content {
    padding: 7.5rem 10rem 7.5rem 10rem;
  }

  .installationRules_content
    > .installationRules_content_foundation:not(:last-child) {
    margin-bottom: 5rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .installationRules_grey {
    display: none;
  }

  .installationRules_content {
    padding: 12rem 0 3.75rem 0;
  }

  .installationRules_content
    > .installationRules_content_foundation:not(:last-child) {
    margin-bottom: 3.5rem;
  }

  .installationRules_content_foundation > h3 {
    margin-bottom: 2rem;
  }
}
