.ourProduct_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7.5rem 0 6.25rem 0;
}

.ourProduct_content > h2 {
  margin-bottom: 2.5rem;
}

.ourProduct_tab {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.5rem;
  border-bottom: 1px solid #777777;
  width: 100%;
  max-width: 36rem;
}

.ourProduct_content > a {
  margin-top: 6.25rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .ourProduct_content {
    padding: 5rem 0;
  }

  .ourProduct_content > h2 {
    margin-bottom: 2rem;
  }

  .ourProduct_tab {
    margin-bottom: 3.5rem;
  }

  .ourProduct_content > a {
    margin-top: 5rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .ourProduct_content {
    padding: 5rem 0 2.5rem 0;
  }

  .ourProduct_content > h2 {
    margin-bottom: 2rem;
  }

  .ourProduct_tab {
    margin-bottom: 2rem;
  }

  .ourProduct_content > a {
    margin-top: 2.5rem;
    width: 100%;
  }
}
