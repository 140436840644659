.inputRadio {
  display: flex;
  align-items: center;
  margin-right: 5rem;
  cursor: pointer;
}

.inputRadio > img {
  margin-right: 1rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .inputRadio {
    margin-right: 2rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .inputRadio {
    margin-right: 3rem;
    margin-bottom: 1rem;
  }
}
