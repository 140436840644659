.ourProductList_content {
  padding: 7.5rem 0;
}

.ourProductList_content > .ourProductList_content_list:not(:last-child) {
  margin-bottom: 7.5rem;
}

.ourProductList_content_list {
  position: relative;
  z-index: 5;
}

.ourProductList_content_list > h3 {
  margin-bottom: 3rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .ourProductList_content {
    padding: 5rem 0;
  }

  .ourProductList_content > .ourProductList_content_list:not(:last-child) {
    margin-bottom: 3.5rem;
  }

  .ourProductList_content_list > h3 {
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .ourProductList_content {
    padding: 7.5rem 0 2.5rem 0;
  }

  .ourProductList_content > .ourProductList_content_list:not(:last-child) {
    margin-bottom: 3rem;
  }

  .ourProductList_content_list > h3 {
    margin-bottom: 1.5rem;
  }
}
