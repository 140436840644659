.footer {
  background-color: #070e10;
}

.footer_content {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 6.25rem 0 3.25rem 0;
}

.footer_logo {
  margin-bottom: 4.5rem;
  width: 5rem;
  cursor: pointer;
}

.footer_icon {
  display: flex;
  width: 1.5rem;
  cursor: pointer;
}

.footer_icon,
.footer_icon > p:not(:last-child) {
  margin-right: 1.5rem;
  cursor: pointer;
}

.footer_list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 65.75rem;
}

.footer_list_point > p:first-child {
  margin-bottom: 2rem;
  font-weight: 600;
  color: #ffffff;
}

.footer_list_point > a {
  text-decoration: none;
}

.footer_list_point > p:not(:first-child),
.footer_list_point > a > p {
  margin-top: 0.75rem;
  cursor: pointer;
}

.footer_bottom {
  text-align: center;
}

.footer_bottom p {
  opacity: 0.7;
  padding: 1.5rem 0;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .footer_content {
    padding: 5rem 0 5rem 0;
  }

  .footer_logo {
    margin-bottom: 3rem;
  }

  .footer_list {
    max-width: 56.25rem;
  }

  .footer_list_point > p:first-child {
    margin-bottom: 1rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .footer_content {
    flex-direction: column;
    align-items: center;
    padding: 5rem 0 2.5rem 0;
  }

  .footer_social {
    margin-bottom: 2rem;
  }

  .footer_logo {
    margin-bottom: 2rem;
  }

  .footer_list {
    flex-direction: column;
  }

  .footer_list > div:not(:last-child) {
    margin-bottom: 2rem;
  }

  .footer_list_point > p:first-child {
    margin-bottom: 1rem;
  }

  .footer_bottom p {
    padding: 1rem 0;
  }
}
