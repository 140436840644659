.priceCalculation_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7rem 0;
}

.priceCalculation_scheme {
  width: 100%;
  max-width: 36rem;
}

.priceCalculation_scheme > h3 {
  margin-bottom: 2rem;
}

.priceCalculation_scheme_img {
  width: 100%;
  max-width: 36.5rem;
  margin-bottom: 2rem;
}

.priceCalculation_scheme_description {
  width: 100%;
  max-width: 36rem;
}

.priceCalculation_scheme_description > b {
  font-weight: 600;
  color: #f1b52f;
}

.priceCalculation_price_area_forMobile {
  display: none;
}

.priceCalculation_price {
  width: 50%;
}

.priceCalculation_price > h2 {
  margin-bottom: 1.5rem;
}

.priceCalculation_price > p {
  margin-bottom: 3.25rem;
  max-width: 36rem;
}

.priceCalculation_scheme_description_forMobile {
  display: none;
}

.priceCalculation_price_counting > h3 {
  margin-bottom: 2rem;
}

.priceCalculation_price_counting > p {
  margin-bottom: 1.5rem;
}

.priceCalculation_price_typeOfStep {
  display: flex;
  margin-bottom: 2rem;
}

.priceCalculation_scheme_img_forMobile {
  display: none;
}

.priceCalculation_price_sizeOfStep {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.priceCalculation_price_sizeOfStep > div:first-child {
  margin-right: 1.25rem;
}

.priceCalculation_price_sizeOfStep > div {
  max-width: 21rem;
}

.inputSelect {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  width: 100%;
}

.inputSelect > label {
  margin-bottom: 0.75rem;
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  color: #dadada;
}

.inputSelect > select {
  border: none;
  padding: 1rem 1.5rem;
  background-color: #34373c;
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  color: #dadada;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.inputSelect > select:invalid {
  color: rgba(218, 218, 218, 0.5);
}

.inputSelect > select > option {
  cursor: pointer;
}

.inputSelect > select::-webkit-outer-spin-button,
.inputSelect > select::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.priceCalculation_price_area {
  margin-bottom: 2rem;
}

.priceCalculation_price_area > h3 {
  margin-bottom: 2rem;
}

.priceCalculation_price_sizeOfArea {
  display: flex;
}

.priceCalculation_price_sizeOfArea > div:first-child {
  margin-right: 1.25rem;
}

.priceCalculation_price_sizeOfArea > div {
  max-width: 21rem;
}

.priceCalculation_price_calculation {
  display: flex;
  align-items: center;
}

.priceCalculation_price_calculation > button {
  margin-right: 1.5rem;
}

.priceCalculation_price_calculation_text {
  font-size: 16px;
}

.priceCalculation_price_calculation_text > button {
  border: none;
  background: transparent;
  text-decoration-line: underline;
  color: #f1b52f;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}

.priceCalculation_price_calculation_forMobile {
  display: none;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .priceCalculation_content {
    justify-content: center;
    align-items: normal;
    padding: 5rem 0 2.5rem 0;
  }

  .priceCalculation_content_scheme {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 4.5rem;
    margin-right: 1.25rem;
  }

  .priceCalculation_scheme {
    margin-right: 5.5rem;
    max-width: 28rem;
  }

  .priceCalculation_scheme > h3 {
    margin-bottom: 1.5rem;
  }

  .priceCalculation_scheme_description {
    display: none;
    font-size: 18px;
  }

  .priceCalculation_price {
    max-width: 32.5rem;
  }

  .priceCalculation_price > p {
    margin-bottom: 1.5rem;
  }

  .priceCalculation_price > .priceCalculation_scheme_description_forMobile {
    display: block;
    margin-bottom: 3rem;
  }

  .priceCalculation_price_counting > h3 {
    margin-bottom: 1.25rem;
  }

  .priceCalculation_price_counting > p {
    margin-bottom: 1.5rem;
  }

  .priceCalculation_price_typeOfStep {
    margin-bottom: 1.25rem;
  }

  .priceCalculation_price_sizeOfStep {
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 0;
  }

  .priceCalculation_price_sizeOfStep > div {
    max-width: 100%;
  }

  .inputSelect {
    margin-bottom: 1rem;
  }

  .inputSelect > label {
    font-size: 18px;
  }

  .priceCalculation_price_area {
    display: none;
    margin-bottom: 0;
  }

  .priceCalculation_price_area_forMobile {
    display: block;
  }

  .priceCalculation_price_sizeOfArea {
    flex-direction: column;
  }

  .priceCalculation_price_sizeOfArea > div {
    max-width: 100%;
  }

  .priceCalculation_price_calculation {
    display: none;
  }

  .priceCalculation_price_calculation_forMobile {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 67rem;
    padding-bottom: 5rem;
  }

  .priceCalculation_price_calculation_text {
    max-width: 31rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .priceCalculation_content {
    padding: 6.25rem 0 2.5rem 0;
  }

  .priceCalculation_content_scheme {
    display: none;
  }

  .priceCalculation_price {
    width: 100%;
  }

  .priceCalculation_price > h2 {
    margin-bottom: 1rem;
  }

  .priceCalculation_price > p {
    margin-bottom: 1rem;
    max-width: 100%;
  }

  .priceCalculation_price > .priceCalculation_scheme_description_forMobile {
    display: block;
    margin-bottom: 3.5rem;
    max-width: 100%;
  }

  .priceCalculation_price > h3 {
    margin-bottom: 1.25rem;
  }

  .priceCalculation_price_typeOfStep {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .priceCalculation_scheme_img_forMobile {
    display: block;
    margin: 0 auto 3.5rem auto;
  }

  .priceCalculation_price_sizeOfStep {
    margin-bottom: 2rem;
  }

  .priceCalculation_price_sizeOfStep > div {
    margin-bottom: 1rem;
    max-width: 100%;
  }

  .priceCalculation_price_sizeOfStep > div:first-child {
    margin-right: 0;
  }

  .inputSelect {
    margin-bottom: 1rem;
  }

  .inputSelect > label {
    font-size: 16px;
  }

  .priceCalculation_price_sizeOfArea {
    flex-direction: column;
  }

  .priceCalculation_price_sizeOfArea > div {
    max-width: 100%;
  }

  .priceCalculation_price_calculation {
    flex-direction: column;
  }

  .priceCalculation_price_calculation > button {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
