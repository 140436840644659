.connection {
  position: relative;
}

.connection_img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 45%;
  height: 100%;
  background-image: url('/public/assets/images/feedback_img.png');
  background-position: right;
  background-size: cover;
}

.connection_content {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 360px) and (max-width: 1279px) {
  .connection_img {
    top: auto;
    bottom: 0;
    width: 100%;
    height: 32%;
  }

  .connection_content {
    flex-direction: column-reverse;
  }
}
