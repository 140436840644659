.feedbackForm {
  width: 100%;
}

.feedbackForm_content {
  display: flex;
  flex-direction: column;
}

.feedbackForm_contact {
  display: flex;
}

.feedbackForm_contact_phone {
  display: flex;
  flex-direction: column;
  margin: 0 1rem 1.5rem 0;
  width: 50%;
}

.feedbackForm_contact_phone > label {
  margin-bottom: 0.75rem;
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  color: #dadada;
}

.feedbackForm_contact_phone_input {
  border: none;
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: #34373c;
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  color: #dadada;
  outline: none;
}

.feedbackForm_contact_phone > p {
  opacity: 0.8;
  color: #e52042;
}

.feedbackForm_contact > div:last-child {
  width: 50%;
}

.feedbackForm_imgAttach {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  width: 100%;
}

.feedbackForm_imgAttach_button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.feedbackForm_imgAttach_button > label {
  cursor: pointer;
}

.feedbackForm_imgAttach_button_img,
.feedbackForm_imgAttach_list_img {
  margin-right: 0.75rem;
}

.feedbackForm_imgAttach_input {
  display: none;
}

.feedbackForm_imgAttach_list {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 18.75rem;
}

.feedbackForm_imgAttach_list_text {
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  color: #dadada;
}

@media (min-width: 360px) and (max-width: 1919px) {
  .feedbackForm_contact_phone > label {
    font-size: 18px;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .feedbackForm_contact {
    flex-direction: column;
  }

  .feedbackForm_contact > div,
  .feedbackForm_contact > div:last-child {
    width: 100%;
  }

  .feedbackForm_contact_phone > label {
    font-size: 16px;
  }

  .feedbackForm_imgAttach {
    flex-direction: column;
    align-items: start;
  }

  .feedbackForm_imgAttach_button {
    margin-bottom: 1rem;
  }

  .feedbackForm_imgAttach_list {
    max-width: 100%;
  }
}
