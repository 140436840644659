.header {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
}

.headerActive {
  background: rgba(8, 16, 18, 0.92);
}

.header_content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0 1.25rem 0;
}

.header_cross_left,
.header_cross_right {
  position: absolute;
  z-index: 9;
  bottom: -10px;
}

.header_cross_left {
  left: -90px;
}

.header_cross_right {
  right: -90px;
}

.header_logo {
  width: 3rem;
  height: 3rem;
  transform: translateX(-12rem);
  cursor: pointer;
}

.header_burgerMenu {
  width: 2.5rem;
  transform: translateX(12rem);
  cursor: pointer;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .header_cross_left {
    left: -50px;
  }

  .header_cross_right {
    right: -50px;
  }

  .header_logo,
  .header_burgerMenu {
    transform: translateX(0);
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .header_content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }

  .header_cross_left,
  .header_cross_right {
    display: none;
  }

  .header_logo,
  .header_burgerMenu {
    transform: translateX(0);
  }
}
