.circle_text {
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}

.circle_text > p {
  font-weight: 600;
  color: #ffffff;
}

.border {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  width: 3.25rem;
  height: 3.25rem;
  cursor: pointer;
}

.active {
  border-radius: 50%;
  border: 2px solid #f1b52f;
}

.circle {
  border-radius: 50%;
  border: 2px solid #777777;
  width: 3rem;
  height: 3rem;
  background-color: #45494e;
}
