.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  width: 100%;
}

.input > label {
  margin-bottom: 0.75rem;
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  color: #dadada;
}

.input > input {
  border: none;
  padding: 1rem 1.5rem;
  background-color: #34373c;
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  color: #dadada;
  outline: none;
}

.input > input::-webkit-outer-spin-button,
.input > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input > p {
  opacity: 0.8;
  color: #e52042;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .input {
    margin-bottom: 1rem;
  }

  .input > label {
    font-size: 18px;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .input {
    margin-bottom: 1rem;
  }

  .input > label {
    font-size: 16px;
  }
}
