.preloader {
  background: #34373c;
}

.preloader_content {
  padding: 7.5rem 0;
}

.preloader_progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3.5rem 0 2rem 0;
}

.preloader_progressBar {
  width: 100%;
  height: 0.75rem;
  background-color: #070e10;
}

.preloader_progressBar_progress {
  width: 100%;
  height: 0.75rem;
  background-color: #f1b52f;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .preloader_content {
    padding: 5rem 0;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .preloader_content {
    padding: 5rem 0;
  }

  .preloader_progress {
    margin: 2rem 0 1.5rem 0;
  }
}
