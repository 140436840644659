.customContactLink {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.customContactLink_img {
  margin-right: 0.5rem;
  width: 1.5rem;
}
