.product {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.product_img {
  position: relative;
  border: 1px solid #777777;
  width: 100%;
  max-width: 42rem;
  background: linear-gradient(
    293deg,
    rgba(255, 255, 255, 0.8) 0%,
    #fff 0.01%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.product_img > img:first-child {
  width: 100%;
}

.product_img_crossLeft {
  position: absolute;
  top: -10px;
  left: -10px;
}

.product_img_crossRight {
  position: absolute;
  right: -10px;
  bottom: -10px;
}

.product_material {
  margin-left: 2rem;
  width: 100%;
  max-width: 42rem;
}

.product_material_size {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.product_material_size_button {
  margin: 0 10px 10px 0;
  border: 1px #070e10;
  padding: 6px 10px;
  background-color: #34373c;
  cursor: pointer;
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  color: #ffffff;
}

.product_material_size_button_active {
  background-color: #f1b52f;
  color: #070e10;
}

.product_material_appear {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #777777;
}

.product_material_appear_item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.product_material_appear_item > p {
  margin-top: 0.75rem;
  width: 100%;
  max-width: 7.5rem;
}

.product_material_appear_item_circle {
  display: flex;
  margin-left: 1rem;
}

.product_material_result {
  display: flex;
  margin-bottom: 2rem;
}

.product_material_result > p {
  margin-right: 1rem;
  width: 100%;
  max-width: 8rem;
}

.product_material_result_item {
  display: flex;
}

.product_material_result_item > p {
  margin-right: 0.5rem;
  width: auto;
}

.product_material_result_item > p:last-child {
  font-weight: 600;
  color: #ffffff;
}

.product_material_result_price {
  display: flex;
}

.product_material_result_price > p {
  margin-right: 1rem;
  width: 100%;
  max-width: 8rem;
}

.product_material_result_price_item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.product_material_result_price_item > h3 {
  margin-right: 0.75rem;
  width: auto;
  white-space: nowrap;
  color: #f1b52f;
}

.product_material_result_price > div > p {
  opacity: 0.7;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .product_img {
    max-width: 38%;
  }

  .product_material_size_button {
    font-size: 18px;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .product {
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .product_img {
    margin-bottom: 1rem;
  }

  .product_img_crossLeft,
  .product_img_crossRight {
    display: none;
  }

  .product_material {
    margin-left: 0;
  }

  .product_material_size {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.75rem;
  }

  .product_material_size_button {
    font-size: 16px;
  }

  .product_material_appear_item {
    flex-direction: column;
    align-items: flex-start;
  }

  .product_material_appear_item_circle {
    overflow: auto;
    margin-top: 0.75rem;
    margin-left: 0;
    width: 100%;
  }

  .product_material_result {
    margin-bottom: 1.5rem;
  }

  .product_material_result > p {
    max-width: 5.5rem;
  }

  .product_material_result_item > p {
    text-wrap: nowrap;
  }

  .product_material_result_price > p {
    max-width: 5.5rem;
  }

  .product_material_result_price_item {
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .product_material_result_price_item > h3,
  .product_material_result_price_item > p {
    width: auto;
  }
}
