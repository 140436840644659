.benefit {
  position: relative;
}

.benefit_img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 55%;
  width: 45%;
  height: 100%;
  background-image: url('/public/assets/images/benefit_img.png');
  background-position: left;
  background-size: cover;
}

.benefit_listLink {
  padding-top: 7rem;
  margin-bottom: 3.5rem;
}

.benefit_listLink > h2 {
  margin-bottom: 2.5rem;
}

.benefit_listLink > div {
  width: 100%;
  max-width: 37rem;
}

.benefit_listLink > div > div:not(:last-child) {
  margin-bottom: 0.75rem;
}

.benefit_button {
  display: flex;
  padding-bottom: 7.5rem;
}

.benefit_button > a {
  display: flex;
  text-decoration: none;
}

.benefit_button > a:not(:last-child) {
  margin-right: 2.5rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .benefit_listLink {
    padding-top: 5rem;
    margin-bottom: 2.5rem;
  }

  .benefit_listLink > div {
    max-width: 32.5rem;
  }

  .benefit_button {
    flex-direction: column;
    padding-bottom: 5rem;
  }

  .benefit_button > a:not(:last-child) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .benefit_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .benefit_img {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 28.75rem;
    background-position: left;
  }

  .benefit_listLink {
    padding-top: 5rem;
    margin-bottom: 2.5rem;
  }

  .benefit_listLink > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .benefit_button {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 33.25rem;
  }

  .benefit_button > a {
    justify-content: center;
    width: 100%;
  }

  .benefit_button > a:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
