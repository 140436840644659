.buttonTab {
  border: 2px;
  padding: 1rem 1.5rem;
  background: none;
  cursor: pointer;
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  color: #dadada;
}

.active {
  border-bottom: 2px solid #f1b52f;
  background: linear-gradient(
    180deg,
    rgba(241, 181, 47, 0) 0%,
    rgba(241, 181, 47, 0.12) 100%
  );
  color: #ffffff;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .buttonTab {
    font-size: 18px;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .buttonTab {
    padding: 0.5rem 1rem;
    font-size: 16px;
  }
}
