.button {
  box-shadow: 3px 3px 0px 0px #070e10;
  border: 1px solid #f1b52f;
  padding: 1rem 1.5rem;
  background-color: #f1b52f;
  cursor: pointer;
  font-size: 18px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  color: #070e10;
}

.button:active {
  box-shadow: 3px 3px 0px 0px #f1b52f;
  border: 1px solid #070e10;
  background-color: #070e10;
  color: #ffffff;
}

@media (min-width: 360px) and (max-width: 1279px) {
  .button {
    width: 100%;
    font-size: 16px;
  }
}
