.card {
  border: 1px solid #777777;
  width: 100%;
  padding: 2rem;
  background: linear-gradient(
      304deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 56.81%
    ),
    #34373c;
}

.card_title {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.card_title > img {
  margin-right: 1.25rem;
}

.card_title > h4 {
  color: #f1b52f;
}

.card > p {
  font-size: 16px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  color: #dadada;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .card {
    padding: 1.5rem 1rem;
  }

  .card_title {
    margin-bottom: 1rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .card {
    padding: 1rem;
  }

  .card_title {
    margin-bottom: 1rem;
  }
}
