.hero_header {
  display: none;
}

.hero {
  position: relative;
  z-index: 0;
  background: url('/public/assets/images/hero_background.png');
  background-position: right bottom;
  background-size: cover;
}

.hero_content {
  display: flex;
  justify-content: space-between;
  padding-right: 7rem;
}

.hero_main {
  padding: 13.25rem 0 15rem 0;
}

.hero_main > h1 {
  margin-bottom: 0.75rem;
  width: 100%;
  max-width: 43.75rem;
  color: #070e10;
}

.hero_main > p {
  margin-bottom: 2.5rem;
  width: 100%;
  max-width: 23.75rem;
  font-size: 22px;
  color: #070e10;
}

.hero_news {
  z-index: -1;
  width: 100%;
  max-width: 23rem;
  padding: 13.25rem 1.5rem 0 1.5rem;
  background-image: url('/public/assets/images/hero_background_news.png'),
    url('/public/assets/images/hero_background_news.png'),
    linear-gradient(180deg, rgba(255, 217, 131, 0) 0%, #ffd983 100%);
  background-blend-mode: multiply;
  mix-blend-mode: multiply;
}

.hero_news > h2 {
  font-size: 60px;
  color: #070e10;
}

.hero_steps {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url('/public/assets/images/hero_steps.png');
  background-position: right bottom;
  background-size: cover;
  pointer-events: none;
}

@media (min-width: 1600px) and (max-width: 2619px) {
  .hero {
    background: url('/public/assets/images/hero_background_1920.png');
    background-position: right bottom;
    background-size: cover;
  }

  .hero_steps {
    background: url('/public/assets/images/hero_steps_1920.png');
    background-position: right bottom;
    background-size: cover;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .hero_content {
    padding-right: 5rem;
  }

  .hero_main > h1 {
    font-size: 64px;
  }

  .hero_main > p {
    max-width: 20rem;
    font-size: 20px;
  }

  .hero_news > h2 {
    font-size: 48px;
  }
}

@media (min-width: 600px) and (max-width: 1599px) {
  .hero {
    background: url('/public/assets/images/hero_background_1280.png');
    background-position: right bottom;
    background-size: cover;
  }

  .hero_steps {
    background: url('/public/assets/images/hero_steps_1280.png');
    background-position: right bottom;
    background-size: cover;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .hero_wrapper {
    border: 0px;
    padding: 0 1rem;
  }

  .hero_content {
    position: relative;
    margin: 0 auto;
    padding: 0;
  }

  .hero_main {
    width: 100%;
    padding: 8.25rem 0 2rem 0;
  }

  .hero_main > h1 {
    max-width: 20.5rem;
    font-size: 44px;
  }

  .hero_main > p {
    margin-bottom: 17.75rem;
    max-width: 19rem;
    font-size: 18px;
  }

  .hero_main > button {
    position: relative;
    z-index: 1;
  }

  .hero_news {
    position: absolute;
    top: 20rem;
    left: -1rem;
    bottom: 0;
    max-width: 19rem;
    padding: 7.25rem 0.75rem 0 0.75rem;
  }

  .hero_news > h2 {
    font-size: 32px;
  }
}

@media (min-width: 360px) and (max-width: 599px) {
  .hero {
    background: url('/public/assets/images/hero_background_mobile.png');
    background-position: top right;
    background-size: 100% 110%;
    background-repeat: no-repeat;
  }

  .hero_steps {
    background: url('/public/assets/images/hero_steps_mobile.png');
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
}
