.orderPaymentDelivery_content {
  width: 100%;
  max-width: 52.75rem;
  padding: 7.5rem 0;
}

.orderPaymentDelivery_content
  > .orderPaymentDelivery_content_card:not(:last-child) {
  margin-bottom: 5rem;
}

.orderPaymentDelivery_content_card_title {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.orderPaymentDelivery_content_card_title_number {
  margin-right: 2rem;
  border: 1px solid#777;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1rem;
  background: linear-gradient(
      304deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 56.81%
    ),
    #34373c;
}

.orderPaymentDelivery_content_card_title_number > h4 {
  text-align: center;
  color: #f1b52f;
}

.orderPaymentDelivery_content_card_description {
  margin-left: 5.5rem;
}

.orderPaymentDelivery_content_card_description > p > a {
  color: #f1b52f;
}

.orderPaymentDelivery_content_card_description > p:not(:last-child) {
  margin-bottom: 1rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .orderPaymentDelivery_content {
    max-width: 43.75rem;
    padding: 5rem 0;
  }

  .orderPaymentDelivery_content
    > .orderPaymentDelivery_content_card:not(:last-child) {
    margin-bottom: 3.5rem;
  }

  .orderPaymentDelivery_content_card_title {
    margin-bottom: 1rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .orderPaymentDelivery_content {
    padding: 7.5rem 0 2.5rem 0;
  }

  .orderPaymentDelivery_content
    > .orderPaymentDelivery_content_card:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .orderPaymentDelivery_content_card_description {
    margin-left: 0;
  }
}
