.yourProduct {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
}

.yourProduct_content {
  padding: 7.5rem 0;
}

.yourProduct_result {
  display: flex;
  justify-content: space-between;
  margin-top: 3.5rem;
}

.yourProduct_result_product {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 43.5rem;
}

.yourProduct_result_product_img {
  margin-bottom: 1rem;
}

.yourProduct_result_product_description {
  display: flex;
}

.yourProduct_result_product_description > p {
  white-space: nowrap;
}

.yourProduct_result_product_description > p:last-child {
  max-width: 4rem;
  font-weight: 600;
}

.yourProduct_result_calculation {
  width: 100%;
  max-width: 41rem;
}

.yourProduct_result_calculation_price {
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #777777;
  padding-bottom: 0.5rem;
}

.yourProduct_result_calculation_price_sum {
  display: flex;
  margin-bottom: 0.75rem;
}

.yourProduct_result_calculation_price_sum p {
  width: 100%;
}

.yourProduct_result_calculation_price_sum_count {
  display: flex;
  text-align: end;
  width: 100%;
}

.yourProduct_result_calculation_price_sum_count > p:first-child {
  margin-right: 1rem;
}

.yourProduct_result_calculation_price_sum_count > p:last-child {
  font-weight: 600;
}

.yourProduct_result_calculation_price_total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.5rem;
}

.yourProduct_result_calculation_price_total_count > p {
  font-weight: 600;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .yourProduct_content {
    padding: 5rem 0;
  }

  .yourProduct_result {
    flex-direction: column;
  }

  .yourProduct_result_product {
    margin-bottom: 2.5rem;
  }

  .yourProduct_result_calculation {
    max-width: 43.5rem;
  }

  .yourProduct_result_calculation_price_total {
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .yourProduct_content {
    padding: 5rem 0 2.5rem;
  }

  .yourProduct_result {
    flex-direction: column;
    margin-top: 2rem;
  }

  .yourProduct_result_product {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    max-width: 100%;
  }

  .yourProduct_result_product_img {
    max-width: 20.5rem;
  }

  .yourProduct_result_product_description {
    display: none;
  }

  .yourProduct_result_calculation {
    max-width: 100%;
  }

  .yourProduct_result_calculation_price {
    margin-bottom: 1rem;
    padding-bottom: 0;
  }

  .yourProduct_result_calculation_price_sum {
    display: flex;
    margin-bottom: 1rem;
  }

  .yourProduct_result_calculation_price_sum_count {
    flex-direction: column;
    max-width: 8rem;
  }

  .yourProduct_result_calculation_price_total {
    margin-bottom: 2rem;
  }
}
