* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('/public/assets/fonts/RobotoCondensed-Medium.woff2') format('woff2'),
    url('/public/assets/fonts/RobotoCondensed-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  src: url('/public/assets/fonts/Inter-Regular.woff2') format('woff2'),
    url('/public/assets/fonts/Inter-Regular.woff') format('woff');
}

body {
  background-color: #161a1e;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.07;
  background-image: url('/public/assets/images/background.png');
  background-size: 72px 72px;
  z-index: -1;
}

body.blockScroll {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 110%;
  font-family: Roboto Condensed;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  color: #ffffff;
}

h1 {
  font-size: 80px;
}

h2 {
  font-size: 56px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

p,
p > a {
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  color: #dadada;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  h2 {
    font-size: 44px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  p,
  p > a {
    font-size: 18px;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 20px;
  }

  p,
  p > a {
    font-size: 16px;
  }
}
