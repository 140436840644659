.customNavLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  border-bottom: 1px solid #777777;
  width: 100%;
  max-width: 58.25rem;
  padding-bottom: 2rem;
  cursor: pointer;
  text-decoration: none;
}

.customNavLink_title {
  margin-right: 2.5rem;
}

.customNavLink_img {
  width: 2.5rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .customNavLink {
    padding-bottom: 1.5rem;
  }

  .customNavLink_img {
    width: 2rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .customNavLink {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .customNavLink_img {
    width: 1.5rem;
  }
}
