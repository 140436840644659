.slider_content {
  padding: 7.5rem 0;
}

.slider_content > h2 {
  text-align: center;
  margin-bottom: 5rem;
}

.slick-slider {
  position: relative;
}

.slick-list {
  overflow: hidden;
  margin-bottom: 3rem;
}

.slick-track {
  display: flex;
  align-items: center;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  width: 100%;
  max-width: 33.5rem;
  height: 28.75rem;
}

.slick-slide > div {
  width: 100%;
  max-width: 21rem;
}

.slick-center {
  z-index: 1;
  opacity: 1;
  transform: scale(2.1);
  transition: transform 1s ease-out;
}

.slick-arrow {
  position: absolute;
  z-index: 1;
  bottom: 24%;
}

.slick-arrow > img {
  border: 1px solid #777777;
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  cursor: pointer;
}

.slick-prev {
  left: 20.5%;
}

.slick-next {
  right: 20.5%;
}

.slick-slider .slick-dots {
  display: flex !important;
  justify-content: center;
}

.slick-dots div {
  display: flex;
  justify-content: center;
  opacity: 0.7;
  overflow: hidden;
  border: 1px solid #777777;
  width: 6.25rem;
  height: 6.25rem;
}

.slick-dots > li:not(:last-child) {
  margin-right: 0.75rem;
}

.slick-dots > .slick-active > div {
  opacity: 1;
  border: 3px solid #f1b52f;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .slider_content {
    padding: 5rem 0;
  }

  .slider_content > h2 {
    margin-bottom: 3rem;
  }

  .slick-slide {
    max-width: 25rem;
    height: 22.5rem;
  }

  .slick-slide > div {
    width: 100%;
    max-width: 15.5rem;
  }

  .slick-arrow {
    bottom: 26.75%;
  }

  .slick-prev {
    left: 19.5%;
  }

  .slick-next {
    right: 19.5%;
  }

  .slick-dots div {
    width: 4.5rem;
    height: 4.5rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .slider_content {
    padding: 5rem 0 2.5rem 0;
  }

  .slider_content > h2 {
    margin-bottom: 2.5rem;
  }

  .slick-list {
    margin-bottom: 1rem;
  }

  .slick-slide {
    height: 13.5rem;
  }

  .slick-center {
    transform: scale(1.5);
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-slider .slick-dots {
    justify-content: flex-start;
  }

  .slick-dots {
    overflow: scroll;
  }

  .slick-dots li {
    white-space: nowrap;
    width: max-content;
  }

  .slick-dots div {
    width: 3.5rem;
    height: 3.5rem;
  }
}
