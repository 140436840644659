.burgerMenu {
  height: 100vh;
}

.burgerMenu_wrapper {
  height: 100%;
  padding: 0 11rem;
}

.burgerMenu_container {
  margin: 0 auto;
  border-left: 1px solid #777777;
  border-right: 1px solid #777777;
  width: 100%;
  max-width: 98rem;
  height: 100%;
  padding: 0 5rem;
}

.burgerMenu_content {
  padding-top: 5rem;
}

.burgerMenu_navBar {
  margin-bottom: 3rem;
}

.burgerMenu_navBar_download {
  border-bottom: 1px solid #777777;
  width: 100%;
  max-width: 58.25rem;
  padding-bottom: 2rem;
}

.burgerMenu_navBar_download_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.burgerMenu_navBar_download_menu > h2 {
  margin-right: 2.5rem;
}

.burgerMenu_navBar_download_menu_img {
  width: 2.5rem;
}

.burgerMenu_navBar_download_document {
  display: flex;
  margin-top: 3rem;
}

.burgerMenu_navBar_download_document > div:not(:last-child) {
  margin-right: 5rem;
}

.burgerMenu_social {
  display: flex;
}

.burgerMenu_social > p {
  cursor: pointer;
}

.burgerMenu_social > p:not(:last-child) {
  margin-right: 1.5rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .burgerMenu_wrapper {
    padding: 0 4.5rem;
  }

  .burgerMenu_container {
    padding: 0 2.5rem;
  }

  .burgerMenu_navBar_download {
    padding-bottom: 1.5rem;
  }

  .burgerMenu_navBar_download_menu_img {
    width: 2rem;
  }

  .burgerMenu_navBar_download_document {
    margin-top: 2rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .burgerMenu_wrapper {
    padding: 0;
  }

  .burgerMenu_container {
    padding: 0 1rem;
  }

  .burgerMenu_content {
    padding-top: 2.5rem;
  }

  .burgerMenu_navBar {
    margin-bottom: 2.5rem;
  }

  .burgerMenu_navBar_download {
    padding-bottom: 1rem;
  }

  .burgerMenu_navBar_download_menu_img {
    width: 1.5rem;
  }

  .burgerMenu_navBar_download_document {
    display: block;
    margin-bottom: 2rem;
  }

  .burgerMenu_navBar_download_document > div:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
