.container_wrapper {
  padding: 0 11rem;
}

.container {
  position: relative;
  margin: 0 auto;
  border-left: 1px solid #777777;
  border-right: 1px solid #777777;
  width: 100%;
  max-width: 98rem;
  padding: 0 5rem;
}

.container_crossTopLeft,
.container_crossTopRight,
.container_crossBottomRight,
.container_crossBottomLeft {
  position: absolute;
  z-index: 9;
}

.container_crossTopLeft,
.container_crossTopRight {
  top: -10px;
}

.container_crossBottomRight,
.container_crossBottomLeft {
  bottom: -10px;
}

.container_crossTopLeft,
.container_crossBottomLeft {
  left: -10px;
}

.container_crossTopRight,
.container_crossBottomRight {
  right: -10px;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .container_wrapper {
    padding: 0 4.5rem;
  }

  .container {
    padding: 0 2.5rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .container_wrapper {
    padding: 0;
  }

  .container {
    border-left: 0 solid #777777;
    border-right: 0 solid #777777;
    max-width: 100%;
    padding: 0 1rem;
  }

  .container_crossTopLeft,
  .container_crossTopRight,
  .container_crossBottomRight,
  .container_crossBottomLeft {
    display: none;
  }
}
