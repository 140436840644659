.feedback {
  width: 100%;
  max-width: 43.25rem;
  padding: 7.5rem 0;
}

.feedback > h2 {
  margin-bottom: 1.5rem;
}

.feedback > p {
  margin-bottom: 2.5rem;
}

@media (min-width: 360px) and (max-width: 1279px) {
  .feedback {
    padding: 2.5rem 0 4.5rem;
  }
}
