.sliderDoc_content {
  padding: 7.5rem 0;
}

.sliderDoc_content > h3 {
  text-align: center;
  margin-bottom: 3.25rem;
}

.slickSliderDoc {
  position: relative;
}

.slickSliderDoc > .slick-list {
  overflow: hidden;
  margin-bottom: 3rem;
}

.slickSliderDoc > .slick-list > .slick-track {
  display: flex;
  align-items: center;
  height: 700px;
}

.slickSliderDoc > .slick-list > .slick-track > .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  width: 100%;
  max-width: 31rem;
  height: 380px;
}

.slickSliderDoc > .slick-list > .slick-track > .slick-slide > div {
  display: flex;
  justify-content: center;
  border: 1px solid #777777;
  width: 100%;
  max-width: 337px;
  height: 380px;
}

.slickSliderDoc > .slick-list > .slick-track > .slick-slide > div > img {
  height: 100%;
  width: auto !important;
}

.slickSliderDoc > .slick-list > .slick-track > .slick-center {
  z-index: 1;
  opacity: 1;
  transform: scale(1.84);
  transition: transform 1s ease-out;
}

.slickSliderDoc > .slick-list > .slick-track > .slick-center > div {
  background: linear-gradient(
    293deg,
    rgba(255, 255, 255, 0.32) 0%,
    rgba(255, 255, 255, 0.4) 0.01%,
    rgba(255, 255, 255, 0.04) 100%
  );
}

.slickSliderDoc > .slick-arrow {
  position: absolute;
  z-index: 1;
  bottom: 19%;
}

.slickSliderDoc > .slick-arrow > img {
  border: 1px solid #777777;
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  cursor: pointer;
}

.slickSliderDoc > .slick-prev {
  left: 23.5%;
}

.slickSliderDoc > .slick-next {
  right: 23.5%;
}

.slickSliderDoc .slickSliderDoc_dots {
  display: flex !important;
  justify-content: center;
}

.slickSliderDoc_dots div {
  display: flex;
  justify-content: center;
  opacity: 0.7;
  overflow: hidden;
  border: 1px solid #777777;
  width: 100px;
  height: 120px;
}

.slickSliderDoc_dots img {
  width: 100px;
  height: 120px;
}

.slickSliderDoc_dots > li:not(:last-child) {
  margin-right: 0.75rem;
}

.slickSliderDoc_dots > .slick-active > div {
  opacity: 1;
  border: 3px solid #f1b52f;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .sliderDoc_content {
    padding: 5rem 0;
  }

  .sliderDoc_content > h3 {
    margin-bottom: 3rem;
  }

  .slickSliderDoc > .slick-list > .slick-track {
    height: 524px;
  }

  .slickSliderDoc > .slick-list > .slick-track > .slick-slide {
    max-width: 24rem;
    height: 282px;
  }

  .slickSliderDoc > .slick-list > .slick-track > .slick-slide > div {
    max-width: 250px;
    height: 282px;
  }

  .slickSliderDoc > .slick-arrow {
    bottom: 20%;
  }

  .slickSliderDoc > .slick-prev {
    left: 21%;
  }

  .slickSliderDoc > .slick-next {
    right: 21%;
  }

  .slickSliderDoc_dots div {
    width: 72px;
    height: 86px;
  }

  .slickSliderDoc_dots img {
    width: 72px;
    height: 86px;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .sliderDoc_content {
    padding: 8.75rem 0 2.5rem 0;
  }

  .sliderDoc_content > h3 {
    margin-bottom: 2.5rem;
    text-align: left;
  }

  .slickSliderDoc > .slick-list {
    margin-bottom: 1rem;
  }

  .slickSliderDoc > .slick-list > .slick-track {
    display: flex;
    align-items: center;
    height: 330px;
  }

  .slickSliderDoc > .slick-list > .slick-track > .slick-slide {
    max-width: 31rem;
    height: 330px;
  }

  .slickSliderDoc > .slick-list > .slick-track > .slick-slide > div {
    width: 328px;
    height: 330px;
  }

  .slickSliderDoc > .slick-list > .slick-track > .slick-center {
    transform: scale(1);
  }

  .slickSliderDoc > .slick-arrow {
    display: none !important;
  }

  .slickSliderDoc .slickSliderDoc_dots {
    justify-content: flex-start;
  }

  .slickSliderDoc_dots {
    overflow: scroll;
  }

  .slickSliderDoc_dots li {
    white-space: nowrap;
    width: max-content;
  }

  .slickSliderDoc_dots div {
    width: 56px;
    height: 67px;
  }

  .slickSliderDoc_dots img {
    width: 56px;
    height: 67px;
  }
}
