.aboutUs {
  position: relative;
  overflow: hidden;
}

.aboutUs_img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 45%;
  height: 100%;
  background-image: url('/public/assets/images/about_img.png');
  background-position: bottom left;
  background-size: cover;
}

.aboutUs_grey {
  position: absolute;
  right: -100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background: #ffffff;
}

.aboutUs_content {
  display: flex;
  justify-content: flex-end;
  padding: 5rem 0;
}

.aboutUs_info {
  width: 50%;
  max-width: 43.5rem;
}

.aboutUs_info > h2 {
  margin-bottom: 0.75rem;
}

.aboutUs_info > h3 {
  margin-bottom: 1.5rem;
}

.aboutUs_info_description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 2rem;
}

.aboutUs_info_description > a {
  color: #f1b52f;
  text-decoration: none;
}

.aboutUs_info_product {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.aboutUs_info_product > h5 {
  margin-right: 1.25rem;
  font-size: 80px;
  color: #f1b52f;
}

.aboutUs_info_product > p {
  width: 100%;
  max-width: 14.5rem;
}

.aboutUs_info_detailed {
  display: flex;
  align-items: flex-end;
}

.aboutUs_info_detailed_img {
  margin-right: 4.5rem;
  box-shadow: 0px 52px 30px 0px rgba(0, 0, 0, 0.7);
  width: 100%;
  max-width: 17.75rem;
}

@media (min-width: 1920px) and (max-width: 2619px) {
  .aboutUs_img {
    background-image: url('/public/assets/images/about_img_1920.png');
    background-position: bottom left;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .aboutUs_img {
    background-image: url('/public/assets/images/about_img_1280.png');
    background-position: bottom left;
  }

  .aboutUs_info_description {
    -webkit-line-clamp: 4;
    font-size: 18px;
  }

  .aboutUs_info_product > h5 {
    font-size: 64px;
  }

  .aboutUs_info_detailed_img {
    margin-right: 3.5rem;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .aboutUs_img {
    top: auto;
    bottom: 0;
    width: 100%;
    height: 28.75rem;
    background-image: url('/public/assets/images/about_img_mobile.png');
    background-position: center;
  }

  .aboutUs_grey {
    display: none;
  }

  .aboutUs_content {
    display: flex;
    justify-content: center;
    padding: 2.5rem 0 33.5rem 0;
  }

  .aboutUs_info {
    width: 100%;
  }

  .aboutUs_info > h3 {
    margin-bottom: 1rem;
  }

  .aboutUs_info_description {
    -webkit-line-clamp: 5;
    font-size: 16px;
  }

  .aboutUs_info_product > h5 {
    margin-right: 0.75rem;
    font-size: 44px;
    text-wrap: nowrap;
  }

  .aboutUs_info_product > p {
    max-width: 11.5rem;
  }

  .aboutUs_info_detailed {
    flex-direction: column;
    align-items: center;
  }

  .aboutUs_info_detailed_img {
    margin-right: 0;
    margin-bottom: 1.5rem;
    max-width: 20.5rem;
  }

  .aboutUs_info_detailed > a {
    width: 100%;
  }
}
