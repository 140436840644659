.ourContact {
  width: 100%;
  max-width: 43.25rem;
  padding: 16.75rem 0 24.75rem 0;
}

.ourContact > h3 {
  margin-bottom: 1.5rem;
  color: #f1b52f;
}

.ourContact > p {
  margin-bottom: 2rem;
  width: 100%;
  max-width: 28rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .ourContact {
    padding: 12.5rem 0 25.5rem 0;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .ourContact {
    padding: 4.5rem 0;
  }
}
