.orderVisualization_grey {
  position: absolute;
  left: -100%;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background: #ffffff;
}

.orderVisualization_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 6rem 0 5rem 0;
}

.orderVisualization_content_text > h2 {
  margin-bottom: 1.5rem;
  font-size: 40px;
}

.orderVisualization_content_text > p {
  width: 100%;
  max-width: 36rem;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .orderVisualization_content {
    padding: 5rem 0 5rem 0;
  }

  .orderVisualization_content_text > h2 {
    font-size: 32px;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .orderVisualization_grey {
    display: none;
  }

  .orderVisualization_content {
    flex-direction: column;
    align-items: center;
    padding: 5rem 0 2.5rem 0;
  }

  .orderVisualization_content_text {
    margin-bottom: 2.5rem;
  }

  .orderVisualization_content_text > h2 {
    font-size: 24px;
  }
}
