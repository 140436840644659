.textarea {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
}

.textarea > label {
  margin-bottom: 1.25rem;
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
}

.textarea > textarea {
  border: none;
  height: 11.25rem;
  padding: 1rem 1.5rem;
  background: #34373c;
  font-size: 20px;
  line-height: 150%;
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  color: #dadada;
  outline: none;
  resize: none;
}

.textarea > p {
  opacity: 0.8;
  color: #e52042;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .textarea > label {
    font-size: 18px;
  }
}

@media (min-width: 360px) and (max-width: 1279px) {
  .textarea > label {
    font-size: 16px;
  }
}
